body {
	background-color: var(--border-color);
	margin: 0;
	font-family: var(--font);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	--p1-checker-color: lightseagreen;
	--p2-checker-color: lightskyblue;
	--point-color-1: peachpuff;
	--point-color-2: orchid;
	--border-color: rebeccapurple;
	--background-color: plum;
	--pentagon: polygon(
		50.00000000000001% 0%,
		2.447174185242325% 34.54915028125262%,
		20.610737385376332% 90.45084971874736%,
		79.38926261462365% 90.45084971874738%,
		97.55282581475768% 34.54915028125264%
	);
	--heptagon: polygon(
		50% 100%,
		89.0915741234015% 81.17449009293668%,
		98.74639560909118% 38.87395330218428%,
		71.69418695587791% 4.951556604879045%,
		28.3058130441221% 4.951556604879045%,
		1.2536043909088193% 38.87395330218427%,
		10.908425876598507% 81.17449009293667%
	);
	--font: monospace;
	-webkit-user-select: none;
	user-select: none;
}

button {
	background-color: unset;
	border: unset;
}

input {
	font-family: var(--font);
}

select {
	font-family: var(--font);
}

.player-X .face {
	background-color: var(--p1-checker-color);
}

.player-O .face {
	background-color: var(--p2-checker-color);
}

.quarter {
	display: flex;
	width: inherit;
	flex-direction: row;
	background-color: var(--background-color);
	justify-content: space-around;
}

.player-X .quarter.oside {
	align-items: flex-start;
	flex-direction: row-reverse;
}

.player-O .quarter.xside {
	align-items: flex-start;
	flex-direction: row;
}

.player-X .quarter.xside {
	align-items: flex-end;
	flex-direction: row;
}

.player-O .quarter.oside {
	align-items: flex-end;
	flex-direction: row-reverse;
}

* {
	box-sizing: border-box;
}

.board {
	display: grid;
	background-color: var(--background-color);
	width: 100%;
	height: 100vh;
	height: 100svh;

	grid-template-columns: 1fr 8fr 1fr 8fr 1fr;
	grid-template-rows: minmax(7%, 1fr) minmax(30%, 45%) minmax(30%, 45%) minmax(
			7%,
			1fr
		);
}

.board.player-X {
	grid-template-areas:
		'top top top top top'
		'ofree q4 rail q3 right'
		'xfree q1 rail q2 right'
		'bottom bottom bottom bottom bottom';
}

.board.player-O {
	grid-template-areas:
		'top top top top top'
		'xfree q1 rail q2 right'
		'ofree q4 rail q3 right'
		'bottom bottom bottom bottom bottom';
}

.top {
	grid-area: top;
}

.rail {
	grid-area: rail;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: var(--border-color);
}

.player-O .rail {
	grid-template-areas: 'ojail' 'xjail';
}

.player-X .rail {
	grid-template-areas: 'xjail' 'ojail';
}

.rail > div,
.rail > button {
	width: 100%;
}

.doubling {
	z-index: 2;
	grid-area: center;
	background-color: var(--border-color);
	cursor: pointer;
}

.doubling .face {
	background-color: whitesmoke;
}

.bottom {
	grid-area: bottom;
	justify-content: center;
	font-weight: bold;
	color: var(--background-color);
}

.rhs {
	grid-area: right;
	flex-direction: column;
	justify-content: center;
	justify-content: space-between;
}

.rhs > div {
	width: 100%;
}

.q1 {
	grid-area: q1;
}

.q2 {
	grid-area: q2;
}

.q3 {
	grid-area: q3;
}

.q4 {
	grid-area: q4;
}

.border {
	background-color: var(--border-color);
	width: inherit;
	display: flex;
	align-items: center;
	z-index: 1;
}

.border.top {
	justify-content: center;
}

.player-X .xfree {
	grid-area: xfree;
	flex-direction: column;
}

.player-X .ofree {
	grid-area: ofree;
	flex-direction: column-reverse;
}

.xjail,
.ojail {
	flex-basis: 50%;
}

.player-X .xjail {
	grid-area: xjail;
	flex-direction: column-reverse;
}

.player-X .ojail {
	grid-area: ojail;
	flex-direction: column;
}

.player-O .xfree {
	grid-area: xfree;
	flex-direction: column-reverse;
}

.player-O .ofree {
	grid-area: ofree;
	flex-direction: column;
}

.player-O .xjail {
	grid-area: xjail;
	flex-direction: column;
}

.player-O .ojail {
	grid-area: ojail;
	flex-direction: column-reverse;
}

.point {
	display: flex;
	flex-direction: column;
	background-color: transparent;
	border: none;
	align-items: center;
	width: inherit;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	position: relative;
}

.player-X .quarter.oside .point {
	flex-direction: column;
}

.player-X .quarter.xside .point {
	flex-direction: column-reverse;
}

.player-O .quarter.xside .point {
	flex-direction: column;
}

.player-O .quarter.oside .point {
	flex-direction: column-reverse;
}

.history .checker {
	min-width: 5px;
	min-height: 5px;
	aspect-ratio: 1;
	margin: 1px;
}

.checker {
	width: 75%;
	min-width: 10px;
	min-height: 10px;
	aspect-ratio: 1;
	margin: 2px;
	border-radius: 100px;
	z-index: 1;
}
.match-score {
	position: absolute;
	z-index: 2;
	display: flex;
}

.match-score .score {
	display: flex;
}

.match-score .checker {
	width: 15px;
	height: 15px;
	margin: 2px;
	border-radius: 10px;
}

.match-score .checker {
	background-color: var(--background-color);
	filter: opacity(30%);
}

.match-score .playerX .checker {
	background-color: var(--p1-checker-color);
	filter: opacity(100%);
}

.match-score .playerO .checker {
	background-color: var(--p2-checker-color);
	filter: opacity(100%);
}

.player-O .ojail .checker,
.player-O .ofree .checker,
.player-X .xjail .checker,
.player-X .xfree .checker,
.xs .checker {
	background-color: var(--p1-checker-color);
}

.colorblind .match-score .playerX .checker,
.colorblind .player-O .ojail .checker,
.colorblind .player-X .pip,
.colorblind .player-O .ofree .checker,
.colorblind .player-X .xjail .checker,
.colorblind .player-X .xfree .checker,
.colorblind .xs .checker {
	clip-path: var(--pentagon);
}

.player-X .xjail .checker,
.player-X .ojail .checker,
.player-X .xs .checker,
.player-X .xs .backer,
.player-O .os .backer,
.player-O .os .checker {
	cursor: pointer;
}

.player-O .xjail .checker,
.player-O .xfree .checker,
.player-X .ojail .checker,
.player-X .ofree .checker,
.os .checker {
	background-color: var(--p2-checker-color);
}

.colorblind .match-score .playerO .checker,
.colorblind .player-O .pip,
.colorblind .player-O .xjail .checker,
.colorblind .player-O .xfree .checker,
.colorblind .player-X .ojail .checker,
.colorblind .player-X .ofree .checker,
.colorblind .os .checker {
	clip-path: var(--heptagon);
}

.colorblind .doubling .pip {
	clip-path: unset;
}

.xjail.selected .checker,
.point.selected .checker {
	filter: drop-shadow(1px 1px 2px black);
}

.point.valid-dest,
.xfree.border.valid-dest {
	filter: drop-shadow(1px 1px 2px black) brightness(110%);
	cursor: pointer;
}

.point .backer {
	width: inherit;
	min-width: 15px;
	height: 80%;
	max-height: inherit;
	position: absolute;
	z-index: 0;
}

.player-O .dark .backer,
.player-X .light .backer {
	background-color: var(--point-color-1);
}

.player-O .light .backer,
.player-X .dark .backer {
	background-color: var(--point-color-2);
}

.player-O .quarter.oside .backer,
.player-X .quarter.xside .backer {
	clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.player-X .quarter.oside .backer,
.player-O .quarter.xside .backer {
	clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}

.gg-undo {
	box-sizing: border-box;
	position: relative;
	display: block;
	width: 14px;
	transform: scale(var(--ggs, 1));
	transform-origin: top left;
	height: 14px;
	border: 2px solid;
	border-left-color: transparent;
	border-radius: 100px;
	color: #333;
}

.gg-undo::before {
	content: '';
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 6px;
	height: 6px;
	border-top: 2px solid;
	border-left: 2px solid;
	top: -3px;
	left: -1px;
	transform: rotate(-68deg);
	color: #333;
}

.gg-check {
	position: relative;
	display: block;
	width: 14px;
	transform: scale(var(--ggs, 1));
	transform-origin: top left;
	height: 14px;
	border: 2px solid transparent;
	border-radius: 100px;
}

.gg-check::after {
	content: '';
	display: block;
	position: absolute;
	left: -3px;
	top: -7px;
	width: 5px;
	height: 14px;
	border-width: 0 2px 2px 0;
	border-style: solid;
	transform-origin: bottom left;
	transform: rotate(36deg);
	color: #333;
}

.gg-play-forwards {
	box-sizing: border-box;
	position: relative;
	display: block;
	left: 7px;
	transform: scale(var(--ggs, 1));
	width: 4px;
	height: 15px;
	background-color: #333;
}

.gg-play-forwards::after,
.gg-play-forwards::before {
	content: '';
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 0;
	height: 15px;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	border-left: 6px solid;
	left: -7px;
	color: #333;
}

.gg-play-forwards::before {
	left: -14px;
}

.gg-play-pause {
	box-sizing: border-box;
	position: relative;
	display: block;
	left: 5px;
	transform: scale(var(--ggs, 1));
	width: 4px;
	height: 15px;
	background-color: #333;
}

.gg-play-pause::before {
	content: '';
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 0;
	height: 15px;
	border-left: 4px solid;
	left: -7px;
	color: #333;
}

.hidden {
	visibility: hidden;
}

.history .board {
	height: 50vh;
	height: 50svh;
}

.history {
	flex-wrap: wrap;
	display: flex;
}

.configBackdrop {
	position: absolute;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	z-index: 10;
}

.configBorder {
	width: 85%;
	height: 85%;
	background-color: var(--border-color);
	/* makePath(5, Math.PI * 41/40, 1) */
	clip-path: polygon(
		46.07704521360778% 0.1541333133435927%,
		1.3815039801161788% 38.3277318072047%,
		23.875071764202527% 92.6320082177046%,
		82.47240241650915% 88.02029828000157%,
		96.19397662556435% 30.865828381745505%
	);
}

.configBackground {
	height: 100%;
	width: 100%;
	padding-bottom: 6%;
	background-color: var(--background-color);
	/* makePath(5, Math.PI, 0.95) */
	clip-path: polygon(
		50.00000000000001% 2.5%,
		4.824815475980209% 35.32169276718999%,
		22.080200516107517% 88.42830723281%,
		77.91979948389246% 88.42830723281%,
		95.1751845240198% 35.321692767190015%
	);
}

.config {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	text-align: center;
}

.configItem {
	margin: -4px -5px;
	padding: 6px 35px;
	height: 70px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.configItem .fixwidth {
	width: 110px;
}

.configItem select,
.configItem input[type='checkbox'] {
	width: 110px;
}

.configItem select,
.configItem input[type='submit'] {
	border: 0;
	padding: 5px;
	height: 30px;
}

.configItem input[type='submit'] {
	width: 150px;
}

#playTill {
	margin-left: 10px;
}
.configItem:nth-child(even) {
	clip-path: polygon(0% 30%, 100% 0%, 100% 100%, 0% 70%);
	background-color: var(--point-color-2);
}

.configItem:nth-child(even) select,
.configItem:nth-child(even) input[type='submit'] {
	background-color: var(--p1-checker-color);
}

.configItem:nth-child(odd) {
	clip-path: polygon(0% 0%, 100% 30%, 100% 70%, 0% 100%);
	background-color: var(--point-color-1);
}

.configItem:nth-child(odd) select,
.configItem:nth-child(odd) input[type='submit'] {
	background-color: var(--p2-checker-color);
}

.log {
	position: absolute;
	color: transparent;
}
