.face {
	display: grid;
	grid-template-areas:
		'. . . . .'
		'. a h c .'
		'. e g f .'
		'. d i b .'
		'. . . . .';

	flex: 0 0 auto;
	grid-template-columns: 2px auto auto auto 2px;
	grid-template-rows: 2px auto auto auto 2px;
	margin: 10%;
	width: 80%;
	aspect-ratio: 1;
	border-radius: 10%;
}

.history .face {
	grid-template-columns: 1px auto auto auto 1px;
	grid-template-rows: 1px auto auto auto 1px;
}

.face.icon {
	align-items: center;
	justify-items: center;
	cursor: pointer;
	color: #333;
	padding: 0;
	grid-template-columns: unset;
	grid-template-rows: unset;
}

.face.icon svg {
	transform: scale(var(--ggs, 1));
	grid-area: g;
}

.face.icon span {
	transform: scale(var(--ggs, 1));
	grid-area: g;
}

.pip {
	display: block;
	align-self: center;
	justify-self: center;
	width: 90%;
	height: 90%;
	border-radius: 50%;
	background-color: #333;
}

.pip:nth-child(1) {
	grid-area: a;
}

.pip:nth-child(2) {
	grid-area: b;
}

.pip:nth-child(3) {
	grid-area: c;
}

.pip:nth-child(4) {
	grid-area: d;
}

.pip:nth-child(5) {
	grid-area: e;
}

.pip:nth-child(6) {
	grid-area: f;
}

.pip:nth-child(7) {
	grid-area: h;
}

.pip:nth-child(8) {
	grid-area: i;
}

/* This selects the last pip of odd-valued dice (1, 3, 5) and positions the pip in the center */
.pip:nth-child(odd):last-child {
	grid-area: g;
}
